import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/login',
		name: 'Login',
		meta: {
			auth: false,
		},
		component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
	},
	{
		path: '/login_from_old/:email/:token',
		name: 'Vana login',
		meta: {
			auth: false,
		},
		component: () => import(/* webpackChunkName: "login-from-old" */ './views/LoginFromOld.vue'),
	},
	{
		path: '/forgot-password',
		name: 'Unustasid parooli',
		meta: {
			auth: false,
		},
		component: () => import(/* webpackChunkName: "login" */ './views/ForgotPassword.vue'),
	},
	{
		path: '/reset_password/:email/:token',
		name: 'Uuenda parool',
		meta: {
			auth: false,
		},
		component: () => import(/* webpackChunkName: "login-from-old" */ './views/ResetPassword.vue'),
	},
	{
		path: '/dashboard',
		name: 'Kokkuvõte',
		meta: {
			auth: true,
		},
		component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
	},
	{
		path: '/settings',
		name: 'Seaded',
		meta: {
		    auth: true,
		},
		component: () => import(/* webpackChunkName: "about" */ './views/Settings.vue'),
	},
	{
		path: '/transactions',
		name: 'Sissekanded',
		meta: {
		    auth: true,
		},
		component: () => import(/* webpackChunkName: "transactions" */ './views/Transactions.vue'),
	},
	{
		path: '/imports',
		name: 'Kannete import',
		meta: {
			auth: true,
		},
		component: () => import(/* webpackChunkName: "transactions" */ './views/Imports.vue'),
	},
	{
		path: '/rules',
		name: 'Reeglid',
		meta: {
			auth: true,
		},
		component: () => import(/* webpackChunkName: "rules" */ './views/Rules.vue'),
	},
	{
		path: '/categories',
		name: 'Kategooriad',
		meta: {
			auth: true,
		},
		component: () => import(/* webpackChunkName: "categories" */ './views/Categories.vue'),
	},
	{
		path: '/migrate',
		name: 'Migreerimine',
		meta: {
			auth: true,
		},
		component: () => import(/* webpackChunkName: "migrate" */ './views/Migrate.vue'),
	}
];

const router = new Router({
	mode: 'history',
	base: '/',
	routes,
});

router.beforeEach((to, from, next) => {
	const loggedIn = localStorage.getItem('user');

	if (to.matched.some(record => record.meta.auth) && !loggedIn && to.path != 'Old login') {
		next('/login');
		return;
	}
	next();
});

export default router;
