<template>
	<b-container>
		<img alt="Vue logo" src="../assets/logo.png">
		<hr>
	</b-container>
</template>

<script>

	export default {
		name: 'Avaleht',
		methods: {

		}
	};
</script>
