<template>
    <div id="app">
        <div id="nav">
			<template v-if="!$store.state.process.migrating">
				<template v-if="isLogged">
					<router-link to="/settings">Seaded</router-link> |
					<router-link to="/imports">Import</router-link> |
					<router-link to="/categories">Kategooriad</router-link> |
					<router-link to="/transactions">Sissekanded</router-link> |
					<router-link to="/rules">Reeglid</router-link> |
				</template>
			</template>

			<template v-if="$store.state.process.migrating">
				<router-link to="/migrate">Andmete import</router-link> |
			</template>

			<template v-if="isLogged">
				[{{ $store.state.user.name }}]
				<b-link @click="logout()">Logi välja</b-link>
			</template>
			<template v-else>
				<router-link to="/login">Logi sisse</router-link>
			</template>
        </div>
		<b-row class="m-2">
			<b-col v-if="isLogged" cols="120" md="20" xl="15">
				<b-list-group>
					<b-list-group-item href="/dashboard"
									   :active="$router.currentRoute.path == '/dashboard'"
					>
						Ülevaade
					</b-list-group-item>
				</b-list-group>
			</b-col>
			<b-col class="ml-2">
				<router-view/>
			</b-col>
		</b-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
    	data() {
    		return {
				menu: [
					{
						header: true,
						title: 'Main Navigation',
						hiddenOnCollapse: true
					},
					{
						href: '/',
						title: 'Dashboard',
						icon: 'fa fa-user'
					},
					{
						href: '/charts',
						title: 'Charts',
						icon: 'fa fa-chart-area',
						child: [
							{
								href: '/charts/sublink',
								title: 'Sub Link'
							}
						]
					}
				]
			}
		},
        computed: {
            ...mapGetters([
                'isLogged'
            ])
        },
        methods: {
            logout () {
                this.$store.dispatch('logout');
            }
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
    #nav {
        padding: 30px;
        a {
            font-weight: bold;
            color: #2c3e50;
            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
</style>
