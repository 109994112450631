import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import Echo from 'laravel-echo';

Vue.use(Vuex);

axios.defaults.baseURL = '/api';

window.axios = axios;

export default new Vuex.Store({
    state: {
    	loader: false,
        user: null,
		teamData: null,
		process: {
        	migrating: false,
		},
		tags: {
        	tags: null,
        	tree: null,
			list: null,
		},
		transactionStats: {
			incomingSum: 0,
			outgoingSum: 0,
			totalSum: 0,
		},
		transactions: [],
		rules: [],
    },
    mutations: {
    	setLoader (state, loaderState) {
    		state.loader = loaderState;
		},
        setUser (state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
            axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;
        },
		setTeamData (state, teamData) {
        	state.teamData = teamData;
			// connect Echo
			/*
			window.Echo = new Echo({
				broadcaster: 'pusher',
				key: process.env.VUE_APP_WEBSOCKETS_KEY,
				wsHost: window.location.hostname,
				wsPort: process.env.NODE_ENV == 'development' ? 6001 : 6002,
				wssPort: process.env.NODE_ENV == 'development'  ? 6001 : 6002,
				forceTLS: process.env.NODE_ENV != 'development' ,
				disableStats: true,
				enabledTransports: ['ws', 'wss'],
				auth: {
					headers: {
						Authorization: `Bearer ${state.user.token}`,
					},
				},
			});
			 */
		},
		setProcess (state, processData) {
        	state.process[processData.process] = processData.value;
		},
		setTransactions (state, transactionsData) {
        	state.transactions = transactionsData;
		},
		setRules (state, rulesData) {
			state.rules = rulesData;
		},
		setTags (state, tagsData) {
			state.tags.tags = tagsData.tags;
			state.tags.tree = tagsData.tree;
			state.tags.list = tagsData.list;
		},
        clearUser () {
            localStorage.removeItem('user');
            location.reload();
        }
    },
    actions: {
        login (context, credentials) {
			context.commit('setLoader', true);
            return axios
                .post('/login', credentials)
                .then(({ data }) => {
					context.dispatch('setupUser', data);
					context.commit('setLoader', false);
                })
        },
		remindPassword (context, credentials) {
			context.commit('setLoader', true);
			return axios
				.post('/request_reset_password', credentials)
				.then(({ data }) => {
					context.commit('setLoader', false);
				})
		},
		resetPassword (context, credentials) {
			context.commit('setLoader', true);
			return axios
				.post('/reset_password', credentials)
				.then(({ data }) => {
					context.dispatch('setupUser', data);
					context.commit('setLoader', false);
				})
		},
		updatePassword (context, credentials) {
			context.commit('setLoader', true);
			return axios
				.post('/update_password', credentials)
				.then(({ data }) => {
					context.dispatch('setupUser', data);
					context.commit('setLoader', false);
				})
		},
		setupUser (context, data) {
			context.commit('setLoader', true);
			context.commit('setUser', data);
			context.dispatch('loadTeamData');
		},
		loadTeamData(context) {
			axios.get('/team_data').then(({data}) => {
				context.commit('setTeamData', data);
				context.commit('setLoader', false);
			})
		},
        logout (context) {
			context.commit('clearUser');
        },
		loadRules (context) {
			context.commit('setLoader', true);
			axios.get('/rules').then(({data}) => {
				context.commit('setRules', data);
				context.commit('setLoader', false);
			})
		},
		saveRule (context, rule) {
        	context.commit('setLoader', true);
			axios.post('/rule', rule).then(({data}) => {
				context.dispatch('loadRules', data);
			})
		},
		loadTags (context) {
			context.commit('setLoader', true);
			axios.get('/tags').then(({data}) => {
				context.commit('setTags', data);
				context.commit('setLoader', false);
			})
		},
		pickTeam({ commit }, teamUid) {
			axios
				.get('/pick_team/'+teamUid)
				.then(({data}) => {
					commit('setTeamData', data);
				})
		},
    },
    getters : {
        isLogged: state => !!state.user,
		canNavigate: state => {
        	return
		}
    }
});
